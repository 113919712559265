
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form, useField, useForm } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import { getModule } from "vuex-module-decorators";
import { AuthModule } from "@/store/modules";

interface Login {
  username: string;
  password: string;
}

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },

  setup() {
    const { t, te } = useI18n();
    const i18n = (text) => (te(text) ? t(text) : text);

    const store = useStore();
    const router = useRouter();
    const authModule = getModule(AuthModule, store);

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string()
        .required()
        .label(i18n("auth.signin.usernameLabel")),
      password: Yup.string()
        .min(8, i18n("auth.signin.passwordlength"))
        .required()
        .label(i18n("auth.signin.passwordLabel")),
    });

    useForm<Login>({
      validationSchema: login,
    });

    const { value: password, resetField: resetPassword } = useField("password");

    const passwordInput = ref<HTMLElement>();

    //Asi se crea reactiva
    const messageError = ref<string>("");

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      authModule.logOut();

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      authModule
        .login(values)
        .then(() => {
          // Go to page after successfully login
          router.push({ name: "dashboard" });
        })
        .catch(() => {
          messageError.value = i18n("auth.signin.invalidLogin");
          resetPassword();
          passwordInput.value?.focus();
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      messageError,
      password,
      passwordInput,
    };
  },
});
